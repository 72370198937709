import S from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import { bool, func, string, number, shape } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Primary } from 'common/mdc/layout/index';
import Pagination from 'common/mdc/table/pagination';
import CollectionWrapper from 'common/mdc/wrappers/collection/index';
import { actions, selectors } from '@sma/store/device/alarms';
import AlarmsToolbar from './toolbar';
import AlarmTable from './table';

const messages = defineMessages({
  title: {
    id: 'title.alarms',
    defaultMessage: 'Alarm log records',
  },
  alarmsPerPage: {
    id: 'table.pagination.alarms',
    defaultMessage: 'Alarm log records per page',
  },
  alarmsDescription: {
    id: 'alarms.description',
    defaultMessage: '{count, number} alarm log {count, plural, one {record} other {records}} of {total} displayed',
  },
  noAlarms: {
    id: 'alarm.noAlarms',
    defaultMessage: 'No alarm log records found',
  },
  alarmsLoading: {
    id: 'alarm.alarmsLoading',
    defaultMessage: 'Alarm log records loading',
  },
  noAlarmsConfigured: {
    id: 'alarm.noAlarmsConfigured',
    defaultMessage: 'No alarm log records has been discovered yet',
  },
  noAlarmsMatches: {
    id: 'alarm.noAlarmsMatches',
    defaultMessage: 'Search result is too narrow, no alarm log records matches specified query',
  },
});

const Alarms = ({
  alarmsContext,
  loading,
  total,
  paginator,
  onPaginationChange,
  isSorted,
  toggleSort,
  query,
}) => (
  <Primary loading={loading}>
    <CollectionWrapper
      loading={loading}
      context={alarmsContext}
      tools={<AlarmsToolbar />}
      pagination={S.maybeToNullable(S.map(props => (
        <Pagination
          {...props}
          onChange={onPaginationChange}
          itemsPerPage={messages.alarmsPerPage}
        />
	  ), paginator))}
      messages={{
        empty: <FormattedMessage {...messages.noAlarms} />,
        loading: <FormattedMessage {...messages.alarmsLoading} />,
        summary: count => (
          <FormattedMessage
            values={{ count, total }}
            {...messages.alarmsDescription}
          />
        ),
        emptyReasons: [
          <FormattedMessage {...messages.noAlarmsConfigured} />,
          query && <FormattedMessage {...messages.noAlarmsMatches} />,
        ],
      }}
    >{alarms => (
      <AlarmTable
        alarms={alarms}
        isSorted={isSorted}
        toggleSort={toggleSort}
      />
    )}
    </CollectionWrapper>
  </Primary>
);

Alarms.propTypes = {
  loading: bool.isRequired,
  query: string,
  alarmsContext: shape().isRequired,
  total: number.isRequired,
  paginator: shape().isRequired,
  onPaginationChange: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
};

Alarms.defaultProps = {
  query: '',
};

export default connect(
  state => ({
    loading: selectors.isLoading(state),
    alarmsContext: selectors.getAlarmsContext(state),
    total: S.fromMaybe(0, selectors.getTotal(state)),
    isSorted: selectors.isSorted(state),
    paginator: selectors.getPaginator(state),
    query: selectors.getQuery(state),
  }),
  ({
    load: actions.load,
    onPaginationChange: actions.paginationChange,
    toggleSort: actions.toggleSort,
  }),
)(Alarms);
