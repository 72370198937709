import React from 'react';
import * as R from 'ramda';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { string, func } from 'prop-types';
import Menu, { MenuItem } from 'common/mdc/menu';
import WithDialog from 'common/mdc/dialog/withDialog';
import { actions } from '@sma/store/device/ping';
import RelayConnectDisconnect from './relayConnectDisconnect';

import {
  POWER_SWITCH,
  ON_DEMAND_READ,
} from './addOns';

const messages = defineMessages({
  onDemandReading: {
    id: 'table.actions.onDemandReading',
    defaultMessage: 'On demand reading',
  },
  relay: {
    id: 'table.actions.relay',
    defaultMessage: 'Relay connect/disconnect',
  },
  connectivityCheck: {
    id: 'table.actions.connectivityCheck',
    defaultMessage: 'Meter ping',
  },
});

const DeviceTableActions = ({
  device,
  relayConnectDisconnect,
  ping,
  ...props
}, {
  onDemandReading,
}) => (
  <Menu {...props}>
    <MenuItem key={messages.connectivityCheck.id} onClick={() => ping(device.deviceId)}>
      <FormattedMessage {...messages.connectivityCheck} />
    </MenuItem>
    {device.addOns && device.addOns.includes(ON_DEMAND_READ) && (
      <MenuItem
        key={messages.onDemandReading.id}
        onClick={() => onDemandReading({
          deviceId: device.deviceId,
        })}
      >
        <FormattedMessage {...messages.onDemandReading} />
      </MenuItem>
    )}
    {device.addOns && device.addOns.includes(POWER_SWITCH) && (
      <MenuItem
        key={messages.relay.id}
        onClick={() => relayConnectDisconnect({
          deviceId: device.deviceId,
        })}
      >
        <FormattedMessage {...messages.relay} />
      </MenuItem>
    )}
  </Menu>
);

DeviceTableActions.propTypes = {
  deviceId: string.isRequired,
};

DeviceTableActions.contextTypes = {
  onDemandReading: func.isRequired,
};

export default R.compose(
  connect(
    null,
    {
      ping: actions.load,
    },
  ),
  WithDialog(RelayConnectDisconnect, 'relayConnectDisconnect'),
)(DeviceTableActions);
