import ApiService from 'common/service/api';
import { map } from 'rxjs/operators';

export default class WidgetService extends ApiService {
  constructor(config) {
    super(config);

    this.baseURL = `${this.baseURL}/widget`;
  }

  getWidgetDeviceStatusData() {
  	return this.fetch('/status', {}).pipe(map(ApiService.collectionJson),);
  }

  getWidgetDeviceNetworkStatusData() {
  	return this.fetch('/networkStatus', {}).pipe(map(ApiService.collectionJson),);
  }

  getWidgetDeviceConsumerStatusData() {
  	return this.fetch('/consumerStatus', {}).pipe(map(ApiService.collectionJson),);
  }

  getWidgetDeviceSupplyStatusData() {
  	return this.fetch('/supplyStatus', {}).pipe(map(ApiService.collectionJson),);
  }
}
