import { combineReducers } from 'redux';

import statusData from './status/reducer';
import networkData from './network/reducer';
import consumerData from './consumer/reducer';
import supplyData from './supply/reducer';

export default combineReducers({
  statusData,
  networkData,
  consumerData,
  supplyData
});
