import React from 'react';
import { isEmpty } from 'ramda';
import { shape, number, string, oneOfType } from 'prop-types';
import DateTime from 'common/components/dateTime';
import DLMSDateTimeParser from './dlmsDateTimeParser';

const unitValue = (value, unit) => `${value} ${unit || ''}`;
const getExp = value => (value.toExponential ? value.toExponential(2) : value);
const getFixed = value => (value.toFixed ? value.toFixed(2) : value);
const expValue = value => (value < 1 ? getExp(value) : getFixed(value));

const RegisterSampleValue = ({ sampleValue, stringValue }) => {
  const scaledValue = Number(sampleValue.scaler ? `${sampleValue.value}e${sampleValue.scaler}` : sampleValue.value);

  const getNumber = () => unitValue(
    Number.isInteger(scaledValue)
      ? scaledValue
      : expValue(scaledValue),
    sampleValue.unit,
  );

  switch (sampleValue.type) {
    case 'DLMS_TYPE_DATE':
    case 'DLMS_TYPE_TIME':
    case 'DLMS_TYPE_DATE_TIME':
      return isEmpty(stringValue) ?
        <DLMSDateTimeParser
          date={sampleValue.value.date}
          time={sampleValue.value.time}
          deviation={sampleValue.value.deviation}
        /> :
        <DateTime value={stringValue} title seconds />;
    case 'DLMS_TYPE_DOUBLE_LONG':
    case 'DLMS_TYPE_DOUBLE_U_LONG':
    case 'DLMS_TYPE_INTEGER':
    case 'DLMS_TYPE_LONG':
    case 'DLMS_TYPE_U_LONG':
    case 'DLMS_TYPE_LONG64':
    case 'DLMS_TYPE_U_LONG64':
    case 'DLMS_TYPE_FLOAT32':
    case 'DLMS_TYPE_FLOAT64':
      return (
        <span title={getNumber()}>
          {getNumber()}
        </span>
      );
    case 'DLMS_TYPE_OCTET_STRING':
      return sampleValue.value && sampleValue.value.octetString;
    default:
      return (
        <span title={getNumber()}>
          {getNumber()}
        </span>
      );
  }
};

RegisterSampleValue.propTypes = {
  sampleValue: shape({
    scaler: number,
    type: string,
    unit: string,
    value: oneOfType([string, shape(), number]),
  }).isRequired,
};

export default RegisterSampleValue;
