import { combineEpics, ofType } from 'redux-observable';
import { map, catchError, switchMap, debounceTime } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';

import {
  LOAD, load,
  loadSuccess,
  loadError,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  SEARCH,
  SET_SELECTED,
  ADVANCED_SEARCH,
} from './actions';

import {
  getStoreParams,
} from './selectors';

import {
  actions,
} from '../device';

const loadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => deviceService
    .getAll(getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const update = action => action.pipe(
  ofType(TOGGLE_SORT, PAGINATION_CHANGE, ADVANCED_SEARCH),
  map(load),
);

const search = action => action.pipe(
  ofType(SEARCH),
  debounceTime(150),
  map(load),
);

const loadSelected = action => action.pipe(
  ofType(SET_SELECTED),
  map(({ selected }) => actions.load(selected.deviceId)),
);

export default combineEpics(
  loadEpic,
  update,
  search,
  loadSelected,
);
