import React from 'react';
import { defineMessages, FormattedMessage, intlShape, injectIntl } from 'react-intl';
import Grid, { GridRow, GridCell } from 'common/mdc/grid';
import Main from 'common/mdc/layout';
import WidgetStatus from './widgets/status/status';
import WidgetNetwork from './widgets/network/network';
import WidgetConsumer from './widgets/consumer/consumer';
import WidgetSupply from './widgets/supply/supply';

const messages = defineMessages({
  title: {
    id: 'title.dashboard',
    defaultMessage: 'Dashboard',
  },
});

const Dashboard = () => (
  <Main
    title={<FormattedMessage {...messages.title} />}
  >
    <Grid container className="main-dashboard">
      <Grid item xs={12} sm={6}>
        <GridRow>
          <GridCell>
            <WidgetStatus />
          </GridCell>
          <GridCell>
            <WidgetNetwork />
          </GridCell>
          <GridCell>
            <WidgetConsumer />
          </GridCell>
          {/*<GridCell>
            <WidgetSupply />
          </GridCell>*/}
        </GridRow>
      </Grid>
    </Grid>
  </Main>
);

export default Dashboard;
