import ApiService from 'common/service/api';
import { map } from 'rxjs/operators';

export const CONNECTED_ACTIVE = 'ENABLED_POWER_ON';
export const CONNECTED_INACTIVE = 'ENABLED_POWER_OFF';
export const DISCONNECTED = 'DISABLED_POWER_OFF';

export default class DeviceService extends ApiService {
  constructor(config) {
    super(config);

    this.baseURL = `${this.baseURL}/devices`;
  }

  getAll(params) {
    return this.fetchCollection('', params);
  }

  getMeteringProfiles(deviceId, props = {}) {
    return this.fetchCollection(`/${deviceId}/meteringProfiles`, {
      ...props,
      limit: null,
      offset: null,
    });
  }

  getOnDemandSamples(deviceId, registers) {
    return this.fetch(`/${deviceId}/actions/reading`, null, {
      method: 'POST',
      body: JSON.stringify(registers),
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      map(ApiService.collectionJson),
    );
  }

  getOnDemandSamplesHistory({ deviceId, ...props }) {
    return this.fetch(`/${deviceId}/actions/history`, null, {
      method: 'POST',
      body: JSON.stringify(props),
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      map(ApiService.collectionJson),
    );
  }

  getMeteringProfileSamples(deviceId, profileId, { limit, offset, sort, search, from, to } = {}) {
    return this.fetchCollection(`/${deviceId}/meteringProfiles/${profileId}/samples`, {
      limit,
      offset,
      sort,
      search,
      from,
      to,
    });
  }

  getById(deviceId) {
    return this.fetchResource(`/${deviceId}`);
  }

  getStatus(deviceId) {
    return this.fetchResource(`/${deviceId}/actions/connection`);
  }

  getPing(deviceId) {
    return this.fetchResource(`/${deviceId}/actions/ping`);
  }

  updateStatus(deviceId, state) {
    return this.fetchResource(`/${deviceId}/actions/connection`, {
      method: 'POST',
      body: JSON.stringify({ state }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getMeteringProfileById(deviceId, meteringProfileId) {
    return this.fetchResource(`/${deviceId}/meteringProfiles/${meteringProfileId}`);
  }

  getAlarms({ deviceId, ...props }) {
    return this.fetchCollection(`/${deviceId}/alarms`, props);
  }

  getEvents({ deviceId, ...props }) {
    return this.fetchCollection(`/${deviceId}/events`, props);
  }
}
