import ConfigService from 'common/service/config';

import {
  AuthService,
  ServerService,
} from 'common/service/api';

import {
  DeviceService,
  GroupService,
  MeteringProfileService,
  WidgetService,
} from '../service/api';

export default function configureServices(intl, config) {
  const configService = new ConfigService(config);
  const authService = new AuthService(configService);
  const deviceService = new DeviceService(configService);
  const serverService = new ServerService(configService);
  const groupService = new GroupService(configService);
  const meteringProfileService = new MeteringProfileService(configService);
  const widgetService = new WidgetService(configService);

  return {
    configService,
    authService,
    deviceService,
    serverService,
    groupService,
    meteringProfileService,
    widgetService,
  };
}
