import S from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import Pagination from 'common/mdc/table/pagination';
import { Primary } from 'common/mdc/layout';
import { func, number, bool } from 'prop-types';
import CollectionWrapper from 'common/mdc/wrappers/collection';

import {
  selectors,
  actions,
} from '@sma/store/device/devices';

import DeviceTable from './table';
import BulkActions from './bulkActions';

const messages = defineMessages({
  devicesSummary: {
    id: 'devices.pagination.summary',
    defaultMessage: '{count, number} {count, plural, one {meter} other {meters}} of {total} displayed',
  },
  createGroup: {
    id: 'devices.pagination.createGroup',
    defaultMessage: 'Create group',
  },
  noDevices: {
    id: 'devices.noDevices',
    defaultMessage: 'No meters found',
  },
  devicesLoading: {
    id: 'devices.devicesLoading',
    defaultMessage: 'Meters loading',
  },
  noDevicesConfigured: {
    id: 'devices.noDevicesConfigured',
    defaultMessage: 'No meters has been discovered in system yet',
  },
  noDevicesMatches: {
    id: 'devices.noDevicesMatches',
    defaultMessage: 'No meters due to filters applied',
  },
  metersPerPage: {
    id: 'devices.metersPerPage',
    defaultMessage: 'Meters per page',
  },
});

const DevicePrimary = ({
  devicesContext,
  total,
  loading,
  isSorted,
  toggleSort,
  paginator,
  onPaginationChange,
  clearSelection,
  isSelected,
  select,
  query,
  search,
}) => (
  <Primary loading={loading}>
    <CollectionWrapper
      loading={loading}
      context={devicesContext}
      pagination={S.maybeToNullable(S.map(props => (
        <Pagination
          {...props}
          onChange={onPaginationChange}
          itemsPerPage={messages.metersPerPage}
        />
      ), paginator))}
      tools={() => (<BulkActions />)}
      messages={{
        loading: <FormattedMessage {...messages.devicesLoading} />,
        empty: <FormattedMessage {...messages.noDevices} />,
        summary: count => (
          <FormattedMessage
            values={{ count, total }}
            {...messages.devicesSummary}
          />
        ),
        emptyReasons: [
          <FormattedMessage {...messages.noDevicesConfigured} />,
          (query || search) && (
            <FormattedMessage
              // values={{ query: (
              //   <ul>
              //     {query && (<li>{query}</li>)}
              //     {search && (<li>{search}</li>)}
              //   </ul>
              // ) }}
              {...messages.noDevicesMatches}
            />
          ),
        ],
      }}
    >{devices => (
      <DeviceTable
        devices={devices}
        isSelected={isSelected}
        isSorted={isSorted}
        toggleSort={toggleSort}
        onSelectionChange={device => (
          isSelected(device)
            ? clearSelection()
            : select(device)
        )}
      />
    )}
    </CollectionWrapper>
  </Primary>
);

DevicePrimary.propTypes = {
  total: number.isRequired,
  loading: bool.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  onPaginationChange: func.isRequired,
  clearSelection: func.isRequired,
  isSelected: func.isRequired,
  select: func.isRequired,
};

export default connect(
  state => ({
    devicesContext: selectors.getDevicesContext(state),
    total: S.fromMaybe(0, selectors.getTotal(state)),
    loading: selectors.isLoading(state),
    isSorted: selectors.isSorted(state),
    paginator: selectors.getPaginator(state),
    isSelected: selectors.isSelected(state),
    query: selectors.getQuery(state),
    search: selectors.getSearch(state),
  }),
  {
    load: actions.load,
    toggleSort: actions.toggleSort,
    onPaginationChange: actions.paginationChange,
    clearSelection: actions.clearSelected,
    select: actions.setSelected,
  },
)(DevicePrimary);
