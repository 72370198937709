import S from 'common/service/sanctuary';
import React from 'react';
import { compose } from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bool, func } from 'prop-types';
// import Select, { SelectItem } from 'common/mdc/select';
// import Menu, { MenuItem, MenuWrapper } from 'common/mdc/menu';
import { Pagination } from 'common/mdc/table';
// import Main, { Primary, Vbox } from 'common/mdc/layout';
// import { CheckboxField } from 'common/mdc/form';
// import { LABEL_AFTER } from 'common/mdc/form/checkbox';
// import Input from 'common/mdc/textfield';
// import Toolbar, { ToolbarRow, ToolbarSection, ALIGN_START, ALIGN_END } from 'common/mdc/toolbar';
import { SamplesTable } from '@sma/components/samples';
// import RegisterName from '@sma/components/device/onDemandReading/name';
// import registerShape from '@sma/components/device/onDemandReading/shape';
import meteringProfileShape from '@sma/components/device/meteringProfile/shape';

import CollectionWrapper from 'common/mdc/wrappers/collection';
import {
  actions,
  selectors,
} from '@sma/store/device/meteringProfileSamples';

import SamplesToolbar from './toolbar';

export const messages = defineMessages({
  summary: {
    id: 'device.samples.summary',
    defaultMessage: '{count, number} of {total, number} {count, plural, one {samples} other {samples}} displayed',
  },
  empty: {
    id: 'device.samples.empty',
    defaultMessage: 'No data samples',
  },
  loading: {
    id: 'device.samples.loading',
    defaultMessage: 'Loading metering profiles',
  },
  emptyReason: {
    id: 'device.samples.emptyReason',
    defaultMessage: 'Metering profile {profileName} has not collected any samples',
  },
  samplesPerPage: {
    id: 'device.samples.samplesPerPage',
    defaultMessage: 'Samples per page',
  },
});

class Samples extends React.PureComponent {
  componentWillMount() {
    const { meteringProfile } = this.props;

    this.props.loadSamples(meteringProfile);
    this.props.setRegisters(meteringProfile.registers);
  }

  render() {
    const {
      loading,
      samplesContext,
      isSorted,
      toggleSort,
      paginator,
      onPaginationChange,
      meteringProfiles,
      meteringProfile,
      isRegisterSelected,
      total,
      deviceId,
    } = this.props;

    const selectedRegisters = S.filter(isRegisterSelected, meteringProfile.registers);

    return (
      <CollectionWrapper
        loading={loading}
        context={samplesContext}
        pagination={S.maybeToNullable(S.map(props => (
          <Pagination
            {...props}
            onChange={onPaginationChange}
            itemsPerPage={messages.samplesPerPage}
          />
        ), paginator))}
        tools={(
          <SamplesToolbar
            meteringProfiles={meteringProfiles}
            meteringProfile={meteringProfile}
            deviceId={deviceId}
          />
        )}
        messages={{
          loading: <FormattedMessage {...messages.loading} />,
          empty: <FormattedMessage {...messages.empty} />,
          summary: count => (
            <FormattedMessage
              values={{ count, total }}
              {...messages.summary}
            />
          ),
          emptyReasons: [
            <FormattedMessage
              values={{ profileName: <b>{meteringProfile.name}</b> }}
              {...messages.emptyReason}
            />,
          ],
        }}
      >{samples => (
        <SamplesTable
          samples={samples}
          isSorted={isSorted}
          toggleSort={toggleSort}
          meteringProfile={meteringProfile}
          registers={selectedRegisters}
        />
      )}
      </CollectionWrapper>
    );
  }
}

Samples.propTypes = {
  loading: bool.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  onPaginationChange: func.isRequired,
  meteringProfile: meteringProfileShape.isRequired,
};

export default compose(
  connect(
    (state, { meteringProfiles }) => ({
      loading: selectors.isLoading(state),
      samplesContext: selectors.getSamplesContext(state),
      isSorted: selectors.isSorted(state),
      paginator: selectors.getPaginator(state),
      meteringProfile: S.fromMaybe(
        S.maybeToNullable(S.head(meteringProfiles)),
        selectors.getMeteringProfile(state),
      ),
      isRegisterSelected: selectors.isRegisterSelected(state),
      total: S.fromMaybe(0, selectors.getTotal(state)),
    }),
    (dispatch, { deviceId }) => bindActionCreators({
      toggleSort: actions.toggleSort,
      loadSamples: meteringProfile => actions.loadAll(deviceId, meteringProfile.id),
      setRegisters: actions.setRegisters,
      onPaginationChange: actions.paginationChange,
    }, dispatch),
  ),
)(Samples);
