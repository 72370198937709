import { combineEpics } from 'redux-observable';

import widgetStatus from './status/epic';
import widgetNetwork from './network/epic';
import widgetConsumer from './consumer/epic';
import widgetSupply from './supply/epic';

export default combineEpics(
  widgetStatus,
  widgetNetwork,
  widgetConsumer,
  widgetSupply,
);
