import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { ToolbarSection } from 'common/mdc/toolbar';
import Input from 'common/mdc/textfield';
import { defineMessages, FormattedMessage } from 'react-intl';
import DatetimeRange from 'common/mdc/form/datetimeRange';
import Icon from 'common/mdc/icon';

import {
    actions, selectors,
} from '@sma/store/device/alarms';

const messages = defineMessages({
  filter: {
    id: 'device.alarms.filter',
    defaultMessage: 'Displaying period',
  },
});

class AlarmsToolbar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.now = new Date();
    }

    render() {
        return (
            <ToolbarSection
                key="filter"
                className="main-toolbar-section--filter"
                align={ToolbarSection.ALIGN_END}
            >
                <div className="mdc-toolbar__text">
                    <FormattedMessage {...messages.filter} />
                </div>
                <DatetimeRange
                    to={this.props.to}
                    from={this.props.from}
                    max={this.now}
                    onChange={this.props.load}
                />
                <div className="mdc-toolbar__text">
                    <label htmlFor="export-csv">
                        <Icon
                            className="mdc-toolbar__icon"
                            onClick={() => fetch(`/ui/devices/${this.props.deviceId}/alarms/download?contentType=text%2fcsv&fileName=alarms%2Ecsv&from=${this.props.from.toISOString()}&to=${this.props.to.toISOString()}`)
                                .then((response) => {
                                    const filename = response.headers.get('Content-Disposition').split('filename=')[1];
                                    response.blob().then(blob => {
                                        const downloadLink = document.createElement('a');
                                        downloadLink.href = URL.createObjectURL(blob);
                                        downloadLink.download = filename;
                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        document.body.removeChild(downloadLink);
                                    });
                                })}
                        >archive</Icon>
                    </label>
                </div>
            </ToolbarSection>
        );
    }
}

export default R.compose(
    connect(
        state => ({
            deviceId: selectors.getDeviceId(state),
            from: selectors.getFrom(state),
            to: selectors.getTo(state),
        }),
        ({
            load: actions.load,
        }),
    ),
)(AlarmsToolbar);